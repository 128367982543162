@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-display: fallback;

  src:
    url("./static/fonts/Pretendard-Regular.otf") format("opentype"),
    url("./static/fonts/Pretendard-Regular.otf") format("opentype"),
    url("./static/fonts/Pretendard-ExtraBold.otf") format("opentype");
    
  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}